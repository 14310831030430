import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { graphql, StaticQuery, Link } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        header: imageSharp(fluid: {
          originalName: {eq: "Home-Hero-1.jpg"}
        }) {
          fluid(maxWidth: 1194, maxHeight: 650, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={data => (
      <SectionWrapper id='main'>
        <Grid container alignItems='center'
          className={styles.container} >
          <Grid item xs={12} sm={12} md={12} lg={7}
            className={clsx(styles.imageContainer, styles.imageContainerMobile)}>
            <Img imgStyle={{ objectFit: 'none'}}
              objectFit='none'
              objectPosition='top right'
              className={styles.image}
              fluid={data.header.fluid}
              alt={i18n('A suite of connected car services')}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5}
            className={styles.textContainer}>
            <Typography
              variant='h1'
              weight='light'
              className={clsx(
                styles.title,
                styles.heroText
              )}>
              {i18n('OBI+ connected car services')}
            </Typography>
            <Typography
              variant='h2'
              weight='regular'
              align='left'
              className={clsx(
                styles.pitch,
                styles.heroText
              )}>
              {i18n('A safer, better smarter future')}
            </Typography>
            <Typography
              color='secondary'
              variant='h3'
              weight='regular'
              align='left'
              className={clsx(
                styles.pitchText,
                styles.heroText
              )}>
                {i18n('powered by data-driven services.')}
            </Typography>
            <div className={clsx(
                styles.titleContainer,
                styles.buttonContainer
              )}>
              <Link to={i18n('_url:sign-up')} style={{ textDecoration: 'none'}}>
                <Button className={styles.button}>
                  {i18n('Get started')}
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7}
            className={clsx(styles.imageContainer, styles.imageContainerWide)}>
            <Img imgStyle={{ objectFit: 'contain'}}
              objectFit='contain'
              className={styles.image}
              fluid={data.header.fluid}
              alt="Flådestyringsløsning obiplus"/>
          </Grid>
        </Grid>
      </SectionWrapper>
    )}/>
);