import React from "react";
import Content from '../views/Main';

export default ({location}) => (
  <Content location={location} seo={{
    title: "En komplet connected car løsning i Danmark",
    lang: 'da-DK',
    description: "Udvid din portefølje med en komplet pakke, udviklet til at muliggøre connected car tjenester.",
    meta: [{
        name: 'keywords',
        content: 'Connected car løsning'
      }]
  }} />
);