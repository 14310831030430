import React from 'react';
import clsx from 'clsx';
import { graphql, StaticQuery, Link } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import SectionWrapper from '../../../components/SectionWrapper';
import Button from '../../../components/Button';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        logo: imageSharp(fluid: {originalName: {eq: "driver-logo-new-2x.png"}}) {
          fluid(maxWidth: 88) {
            ...GatsbyImageSharpFluid
          }
        }
        pic: imageSharp(fluid: {originalName: {eq: "Main-Drive.jpg"}}) {
          fluid(maxWidth: 1180, maxHeight: 670, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ logo, pic }) => (
      <SectionWrapper>
        <Grid container justify='center' alignContent='center' alignItems='center'
          className={styles.container}>
          <Grid item xs={12} sm={12} md={12} lg={7} xl={8}
            className={clsx(styles.imageWrapper, styles.imageWrapperMobile)}>
            <Img imgStyle={{ objectFit: 'none' }}
              objectFit='none'
              objectPosition='top right'
              className={styles.imageContainer}
              fluid={pic.fluid}
              alt={i18n('Car owner using Drive app by Obiplus')}/>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={5} xl={4}
            alignContent='center'
            alignItems='center'
            justify='flex-start'
            className={styles.callToActionContainer}>
            <div className={styles.logoContainer}>
              <Img imgStyle={{ objectFit: 'contain' }} 
                objectPosition='0%'     
                objectFit='contain'
                className={styles.imageContainer}
                fluid={logo.fluid}
                alt={i18n('Mobile application Drive by Obiplus')}/>
            </div>
            <Typography variant='h2'
              weight='bold'
              align='left'
              color='dark'
              className={styles.titleContainer}>
              Drive
            </Typography>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('Enabling seamless digital interactions for car owners through connected car technology and multi-feature mobile applications')}
            </Typography>
            <div className={styles.buttonContainer}>
              <Link to={`${i18n('_url:services')}/#${i18n('_hash:driver-app')}`}
                style={{ textDecoration: 'none'}}>
                <Button className={styles.button}>
                  {i18n('Read more')}
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7} xl={8}
            className={clsx(styles.imageWrapper, styles.imageWrapperWide)}>
            <Img imgStyle={{ objectFit: 'contain' }}
              objectFit='contain'
              objectPosition='100% 0%'
              className={styles.imageContainer}
              fluid={pic.fluid}
              alt={i18n('Car owner using Drive app by Obiplus')}/>
          </Grid>
        </Grid>
      </SectionWrapper>
    )}/>
);