import React from 'react';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <SectionWrapper className={styles.container}>
    <Typography variant='h2'
      weight='bold'
      className={styles.titleContainer}>
      {i18n('Create value for customers')}
    </Typography>
    <Typography variant='subtitle1'
      weight='light'
      className={styles.subtitleContainer}>
      {i18n('Expand your service portfolio quickly and cost-effectively. We are true believers in developing connected car services that are useful for everyone. Choose any of the ready-to-use services to get started right away. All services could work either as a standalone solution or be seamlessly integrated together or into external systems.')}
    </Typography>
  </SectionWrapper>
);